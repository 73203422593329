import { gql } from 'apollo-boost'

export default gql`
  query VerifyVoucher($code: String!) {
    canRedeemVoucher {
      eligible
      issues
    }
    verifyVoucher(code: $code) {
      valid
      voucher {
        id
        months
        status
      }
    }
  }
`
